.fa-clickable {
    color: #444;
    transition: color 2s;
}

.fa-clickable:hover {
    text-decoration: none;
    transition: color 0.1s;
}

.fa-email:hover {
    color: #a739ff;
}

.fa-twitter:hover {
    color: #1DA1F2;
}

.fa-linkedin:hover {
    color: #0077B5;
}

.fa-github:hover {
    color: #000;
}

.fa-reddit:hover {
    color: #FF5700;
}

.social-col {
    padding-left: 8px;
    padding-right: 8px;
}