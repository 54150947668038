@import url(https://fonts.googleapis.com/css2?family=Metamorphous&family=Open+Sans:wght@300;500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

b {
  font-weight: 500;
}

body, h1, h2, p, li {
  color: #555;
}

h1 {
  font-family: 'Metamorphous', serif;
}

.page-header {
  padding-top: 20px;
  padding-bottom: 20px;
}

.header-with-subheader {
  padding-top: 20px;
  padding-bottom: 10px;
}

.header-hr {
  margin-bottom: 32px;
}

h1, h2 {
  margin-top: 0.3em!important;
  text-align: center;
  margin-bottom: 0em!important;
}

body, p, li {
  font-size: 18px;
}

a {
  color: #a739ff;
}

a:hover {
  color: #cd18db;
}

ul {
  list-style: square;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.subheader {
  font-size: 1em;
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-brand {
    font-family: 'Metamorphous', serif;
}

.nav-link {
    font-family: 'Open Sans', sans-serif;
}
.fa-clickable {
    color: #444;
    transition: color 2s;
}

.fa-clickable:hover {
    text-decoration: none;
    transition: color 0.1s;
}

.fa-email:hover {
    color: #a739ff;
}

.fa-twitter:hover {
    color: #1DA1F2;
}

.fa-linkedin:hover {
    color: #0077B5;
}

.fa-github:hover {
    color: #000;
}

.fa-reddit:hover {
    color: #FF5700;
}

.social-col {
    padding-left: 8px;
    padding-right: 8px;
}
.expertise-row {
    padding-bottom: 20px;
}

.expertise-key {
    text-align: right;
    font-weight: 700;
}

.expertise-key > p,
.expertise-value > p {
    margin: 0;
}

.img-me {
    transition: -webkit-filter 1s;
    transition: filter 1s;
    transition: filter 1s, -webkit-filter 1s;
}

.img-me:hover,
.img-me:active {
    -webkit-filter: brightness(150%);
            filter: brightness(150%);
    transition: -webkit-filter 0.75s;
    transition: filter 0.75s;
    transition: filter 0.75s, -webkit-filter 0.75s;
}

.about-info {
    /*margin: 0px;*/
    font-size: 1.1em;
}

.about-personal {
    padding-top: 16px;
}

.about-info > p {
    line-height: 0.75;
}

.img-div {
    display: block;
    overflow: auto;
}
.last-updated {
    text-align: right!important;
    font-size: 0.8em;
}
.blog-description {
    color: gray;
    margin-left: 25%;
    margin-right: 25%;
    text-align: center;
}

.post-row {
    margin-bottom: 2em;
}

.post-image {
    max-width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 0.5em;
}

.post-title {
    font-size: 28px;
    font-weight: 500;
}

.blog-text-col {
    margin-top: -10px;
}

.post-date {
    font-size: 14px;
    margin-top: -5px;
    color: darkgray;
}

.post-excerpt {
    margin-top: -10px;
}
.post-content > h1,
.post-content > h2 {
  text-align: left;
}

.post-content > h2 {
    margin-top: 1em !important;
}

.post-content > p {
    text-align: justify
}

.post-content > p > img {
    max-width: 100%;
    height: auto;
    margin-top: 1rem;
}
.nav-tabs {
    font-size: 1.5rem;
}

.nav-tabs > .nav-item {
    padding-left: 3rem;
    padding-right: 3rem;
    color: #343a40;
    border: 1px solid rgba(0, 0, 0, .1) !important;
    border-bottom: none!important;
}

.nav-tabs > .nav-item.active {
    background-color: #343a40!important;
    color: white!important;
    border: none!important;
}

.tab-container {
    margin-top: 1rem;
}

@media only screen and (max-width: 768px) {
    .nav-tabs > .nav-item {
        width: 100%;
        text-align: center;
    }
}

.project-row {
    margin-top: 2rem;
}

.projects-table > tbody > tr > td {
    border-top:none;
}

.project-stack {
    font-size: 14px;
    text-align: right;
}
