.expertise-row {
    padding-bottom: 20px;
}

.expertise-key {
    text-align: right;
    font-weight: 700;
}

.expertise-key > p,
.expertise-value > p {
    margin: 0;
}

.img-me {
    transition: filter 1s;
}

.img-me:hover,
.img-me:active {
    filter: brightness(150%);
    transition: filter 0.75s;
}

.about-info {
    /*margin: 0px;*/
    font-size: 1.1em;
}

.about-personal {
    padding-top: 16px;
}

.about-info > p {
    line-height: 0.75;
}

.img-div {
    display: block;
    overflow: auto;
}