.nav-tabs {
    font-size: 1.5rem;
}

.nav-tabs > .nav-item {
    padding-left: 3rem;
    padding-right: 3rem;
    color: #343a40;
    border: 1px solid rgba(0, 0, 0, .1) !important;
    border-bottom: none!important;
}

.nav-tabs > .nav-item.active {
    background-color: #343a40!important;
    color: white!important;
    border: none!important;
}

.tab-container {
    margin-top: 1rem;
}

@media only screen and (max-width: 768px) {
    .nav-tabs > .nav-item {
        width: 100%;
        text-align: center;
    }
}

.project-row {
    margin-top: 2rem;
}

.projects-table > tbody > tr > td {
    border-top:none;
}

.project-stack {
    font-size: 14px;
    text-align: right;
}