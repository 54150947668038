.blog-description {
    color: gray;
    margin-left: 25%;
    margin-right: 25%;
    text-align: center;
}

.post-row {
    margin-bottom: 2em;
}

.post-image {
    max-width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 0.5em;
}

.post-title {
    font-size: 28px;
    font-weight: 500;
}

.blog-text-col {
    margin-top: -10px;
}

.post-date {
    font-size: 14px;
    margin-top: -5px;
    color: darkgray;
}

.post-excerpt {
    margin-top: -10px;
}